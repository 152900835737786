import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";

// if (process.env.NODE_ENV == 'production') {
//   console.log('running app in production')
//   axios.defaults.baseURL = process.env.REACT_APP_API_URL_PROD
// } else {
//   console.log('running app in development')
//   axios.defaults.baseURL = process.env.REACT_APP_API_URL_DEV
// }

// I hate the way we're doing this, but don't have time to find something better right now
// axios.defaults.baseURL = "https://api-v2.localeyes.guide";

// axios.defaults.baseURL = "http://localhost:8080";

// axios.defaults.baseURL = "http://localeyes-marie-prod-env.eba-52pvpnf3.us-west-2.elasticbeanstalk.com/";
// axios.defaults.baseURL = "http://mari-v2-test.us-west-2.elasticbeanstalk.com/";
// axios.defaults.baseURL = "https://developbranch.localeyes.guide/"
// axios.defaults.baseURL = "https://develop-mason.talsey.com";
axios.defaults.baseURL = "https://develop-ryan.talsey.com"
// axios.defaults.baseURL = "https://api.talsey.com"
//


axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	// <React.StrictMode>
		<App />
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
